<template>
    <div class="py-16 -mx-6 lg:-mx-8 mt-24 bg-[#F9F9F9]">
        <PageElementWrapper>
            <div class="px-6 lg:px-0">
                <h1 class="text-4xl">
                    {{ title }}
                </h1>
                <p
                    class="mt-4"
                    v-html="text" />
                <div
                    v-if="!loading"
                    class="grid grid-cols-4 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10 gap-8">
                    <StrapiLandingPageBundeslaenderBundesland
                        v-for="federalState in federalStates"
                        :key="federalState.id"
                        :federal-state="federalState" />
                </div>
            </div>
        </PageElementWrapper>
    </div>
</template>

<script setup>
const props = defineProps({
    component: {
        type: Object,
        required: true
    }
})

const title = computed(() => {
    return props.component.Titel ?? ''
})

const text = computed(() => {
    return props.component.Text ?? ''
})

const {find} = useStrapi()

const federalStates = ref(null)
const loading = ref(true)
await useAsyncData('bundeslands', () => find('bundeslands', {populate: {Flagge: {fields: ['url']}, Seite: {fields: ['url', 'Weiterleiten'], populate: ['Unterseiten']}}}), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => federalStates.value = response.data?.value?.data ?? {}).finally(() => loading.value = false)
</script>
